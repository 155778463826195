export default {
    field: {
        name: 'الاسم',
        description: 'الوصف',
        isActive: 'نشطة ؟',
        image: 'صورة',
        dates: 'تخطيط',
        search: 'بحث...',
        question: 'السؤال',
        option: 'الخيار',
        questionType: 'نوع السؤال',
        answerType: 'نوع الإجابة',
        isModel: 'حفظ كنموذج',
        actions: 'العمليات',
        products: 'المنتجات',
        product: 'المنتج',
        brands: 'العلامات ',
        brand: 'العلامة',
        categories: 'الفئات',
        category: 'الفئة',
        subCategories: 'الفئات الفرعية',
        subCategory: 'الفئة الفرعية',
        code: 'الرمز',
        file: 'الملف',
        amount: 'مبلغ الفوز',
        phone: 'الهاتف',
        type: 'النوع',
        city: 'الولاية',
        region: 'المنطقة',
        area: 'المنطقة',
        cluster: 'المجموعة',
        spplyType: 'نوع التوريد',
        zone: 'النطاق',
        route: 'الطريق',
        circuit: 'الدائرة',
        points: 'النقاط',
        conditions: 'المنتجات',
        box: 'علبة',
        quantity: 'الكمية',
        startDate: 'تاريخ البدء',
        endDate: 'تاريخ النهاية',
        title: 'العنوان',
        publicityType: "نوع الإعلان",
        action: 'الرابط',
        small: "Small",
        large: "Large",
        limit: "الحد الأقصى",
        numberAction: "المجموع"
        


        
    }
}
