export default [
    {
        path: '/backoffice',
        component: () => import('../views/backoffice/tempValidation/index'),
        meta: {
            title: 'Backoffice - Validation temporaire ',
            key: 'tempValidation'
        }
    },
    {
        path: '/backoffice/final-validation',
        component: () => import('../views/backoffice/finalValidation/index'),
        meta: {
            title: 'Backoffice - Validation finale ',
            key: 'finalValidation'
        }
    },
    {
        path: '/backoffice/final-validation/:id',
        component: () => import('../views/backoffice/finalValidation/show'),
        meta: {
            title: 'Backoffice - Validation finale ',
            key: 'finalValidation'
        }
    },
    {
        path: '/backoffice/completed',
        component: () => import('../views/backoffice/completed/index'),
        meta: {
            title: 'Backoffice - Terminées',
            key: 'completed'
        }
    },
    {
        path: '/backoffice/completed/:id',
        component: () => import('../views/backoffice/completed/show'),
        meta: {
            title: 'Backoffice - Terminées',
            key: 'completed'
        }
    },
]