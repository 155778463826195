import axios from 'axios';
import store from "@/store";
import router from "@/routes";

export const HTTP = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: false,
    headers: {
        Authorization: 'Bearer ' + store.state.app.user.token,
        'Content-Type': "application/json",
        'Accept': "application/json",
        'X-Requested-With': "XMLHttpRequest",
        'Access-Control-Allow-Origin': "*",
    },
    params: {
        appLang: store.state.app.lang
    }
})

HTTP.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        store.commit('app/user', '')
        store.commit('app/isLoggedIn', false)
        if (router.currentRoute.name !== 'login') {
            window.location.href = '/login'
        }
    }
    return Promise.reject(error)
})