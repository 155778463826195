const state = () => ({
    raisons: [],
    cities: [],
    regions: [],
    questionTypes: [],
    answerTypes: [],
    products: [],
    brands: [],
    categories: [],
    subCategories: [],
    salepointAreas: [],
    salepointCircuits: [],
    salepointClusters: [],
    salepointRoutes: [],
    salepointSupplyTypes: [],
    salepointTypes: [],
    salepointZones: [],
    databases: [],
})
const mutations = {
    raisons: (state, data) => {
        state.raisons = data
    },
    cities: (state, data) => {
        state.cities = data
    },
    regions: (state, data) => {
        state.regions = data
    },
    questionTypes: (state, data) => {
        state.questionTypes = data
    },
    answerTypes: (state, data) => {
        state.answerTypes = data
    },
    products: (state, data) => {
        state.products = data
    },
    brands: (state, data) => {
        state.brands = data
    },
    categories: (state, data) => {
        state.categories = data
    },
    subCategories: (state, data) => {
        state.subCategories = data
    },
    salepointAreas: (state, data) => {
        state.salepointAreas = data
    },
    salepointCircuits: (state, data) => {
        state.salepointCircuits = data
    },
    salepointClusters: (state, data) => {
        state.salepointClusters = data
    },
    salepointRoutes: (state, data) => {
        state.salepointRoutes = data
    },
    salepointSupplyTypes: (state, data) => {
        state.salepointSupplyTypes = data
    },
    salepointTypes: (state, data) => {
        state.salepointTypes = data
    },
    salepointZones: (state, data) => {
        state.salepointZones = data
    },
    databases: (state, data) => {
        state.databases = data
    },
}

export default {
    namespaced: true,
    state,
    mutations
}