<template>
  <v-tooltip top color="primary">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs"
             v-on="on" icon
             @click="$emit('refresh')">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('btn.refresh') }}</span>
  </v-tooltip>
</template>

<script>
export default {}
</script>
<style scoped>
</style>