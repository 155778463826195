<template>
  <div>
    <v-snackbar color="info"
                :value="$infoMessage"
                centered
                timeout="1000">
      <v-icon :left="$float !== 'left'"
              :right="$float !== 'right'"
              color="white">mdi-check
      </v-icon>
      <span v-html="$infoMessage"></span>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  watch: {
    '$infoMessage': function (el) {
      if (el) {
        let _vm = this
        setTimeout(function () {
          _vm.$infoMessage = null
        }, 1000)
      }
    }
  },
}
</script>

<style scoped>

</style>