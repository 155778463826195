export default {
    database: {
        dialog: {

            addDatabase: 'إضافة قاعدة البيانات',
            updateDatabase: 'تعديل قاعدة البيانات',
            renameDatabase: 'إعادة تسمية قاعدة البيانات',

            addType: 'أضف نوع',
            updateType: 'تعديل نوع',

            addArea: 'أضف منطقة',
            updateArea: 'تعديل منطقة',

            addCluster: 'أضف  مجموعة',
            updateCluster: 'تعديل مجموعة',

            addCircuit: 'أضف دائرة',
            updateCircuit: 'تعديل دائرة',

            addZone: 'أضف نطاق',
            updateZone: 'تعديل نطاق',

            addRoute: 'أضف طريق',
            updateRoute: 'تعديل طريق',

            addSupplyType: 'أضف نوع التوريد',
            updateSupplyType: 'تعديل نوع التوريد',
        },
    }
}
