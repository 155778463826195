export default [
    {
        path: '/publicities',
        component: () => import('../views/publicities/list'),
        meta: {
            title: 'Publicities',
        }
    },
    {
        path: '/publicities/create/infoPlanning',
        component: () => import('../views/publicities/create/infoPlanning'),
        meta: {
            title: 'Nouvelle publicite - Info & Planification',
        }
    },
    {
        path: '/publicities/analyses/:id',
        component: () => import('../views/publicities/analyses/index'),
        meta: {
            title: 'Analyses',
        }
    },
]