<template>
  <v-tooltip top color="primary">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs"
             :color="color ? color : 'primary'"
             v-on="on"
             icon
             @click="click">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ title ? title : $t('btn.link') }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ['icon', 'color', 'title'],
  methods: {
    click() {
      this.$emit('click')
    },
  }
}
</script>

<style scoped>

</style>