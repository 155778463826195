<template>
  <div>
    <v-snackbar color="success"
                :value="$successMessage"
                bottom
                left
                timeout="4000">
      <v-icon :left="$float !== 'left'"
              :right="$float !== 'right'"
              color="white">mdi-check
      </v-icon>
      <span v-html="$successMessage"></span>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  watch: {
    '$successMessage': function (el) {
      if (el) {
        let _vm = this
        setTimeout(function () {
          _vm.$successMessage = null
        }, 4000)
      }
    }
  },
}
</script>

<style scoped>

</style>