<template>
  <v-btn :color="color ? color : 'blue'"
         outlined rounded small
         @click="goTo">
    <v-icon left>{{ icon }}</v-icon>
    {{ title }}
  </v-btn>
</template>

<script>
export default {
  props: ['url', 'icon', 'color', 'title'],
  methods: {
    goTo() {
      this.$router.push(this.url)
    },
  }
}
</script>

<style scoped>

</style>