export default {
    campaign: {
        //Page title
        title: {
            key1: 'Info & planning',
            key2: 'Investigations',
            key3: 'Tasks',
        }
    }
}
