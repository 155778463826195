import Vue from 'vue'

Vue.directive('price', {
    bind(el) {
        el.addEventListener('keydown', (e) => {
            if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
                (e.keyCode === 65 && e.ctrlKey === true) ||
                (e.keyCode === 67 && e.ctrlKey === true) ||
                (e.keyCode === 88 && e.ctrlKey === true) ||
                (e.keyCode >= 35 && e.keyCode <= 39)) {
                return
            }
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault()
            }
        })
    }
})
Vue.directive('number', {
    bind(el) {
        el.addEventListener('keypress', (e) => {
            if (e.charCode < 48 || e.charCode > 57) {
                e.preventDefault()
            }
        })
    }
})
