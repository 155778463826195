export default {
    sidebar: {
        key1: 'لوحة القيادة',
        key2: 'الحملات',
        key3: 'المكتب الخلفي',
        key4: 'قاعدة البيانات',
        key5: 'المستخدمون',
        key6: 'حسابي',
        key7: 'الوضع المظلم',
        key8: 'تسجيل الخروج',
        key9: 'المنتجات',
        key10: 'الإعلانات',
    },
}
