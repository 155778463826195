import {publicityObject} from "@/store/objects/publicityObject";

const state = () => ({
    publicity: publicityObject,
    publicityTypes: [],
    stepp1: 0,
    stepp2: 0,
})

const mutations = {
    selectedLanguages: (state, data) => {
        state.publicity.lang = data
    },

    /***Validation***/
    toggelStepp1: (state, boolean) => {
        state.stepp1 = boolean
    },
    toggelStepp2: (state, boolean) => {
        state.stepp2 = boolean
    },

    setPublicitySalepointIds: (state, data) => {
        state.publicity.salepointIds = data.salepointIds
    },

    setPublicityTypes: (state, data) => {
        state.publicityTypes = data
    },

    initialzePublicityData: (state) =>{
        state.publicity = publicityObject
    }

}

export default {
    namespaced: true,
    state,
    mutations
}