export default [
    {
        path: '/campaigns',
        component: () => import('../views/campaigns/list'),
        meta: {
            title: 'Campaigns',
        }
    },
    {
        path: '/campaigns/create/infoPlanning',
        component: () => import('../views/campaigns/create/infoPlanning'),
        meta: {
            title: 'Nouvelle campagne - Info & Planification',
        }
    },
    {
        path: '/campaigns/create/investigations',
        component: () => import('../views/campaigns/create/investigations'),
        meta: {
            title: 'Nouvelle campagne - Enquêtes',
        }
    },
    {
        path: '/campaigns/create/tasks',
        component: () => import('../views/campaigns/create/tasks'),
        meta: {
            title: 'Nouvelle campagne - Tâches',
        }
    },
]