export default {
    sidebar: {
        key1: 'Dashboard',
        key2: 'Campagnes',
        key3: 'Backoffice',
        key4: 'Base de donneés',
        key5: 'Utilisateurs',
        key6: 'Mon compte',
        key7: 'Mode sombre',
        key8: 'Se déconnecter',
        key9: 'Produits',
        key10: 'Publicités',
    },
}
