import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

import otherRoutes from './other'
import dashboardRoutes from './dashboard'
import campaignsRoutes from './campaigns'
import publicitiesRoutes from './publicities'
import backofficeRoutes from './backoffice'
import analyticsRoutes from './analytics'
import productManagmentRoutes from './productManagment'
import databasesRoutes from './databases'

Vue.use(VueRouter)

const routes = [
    ...otherRoutes,
    ...dashboardRoutes,
    ...campaignsRoutes,
    ...backofficeRoutes,
    ...analyticsRoutes,
    ...productManagmentRoutes,
    ...databasesRoutes,
    ...publicitiesRoutes,
]
const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return {x: 0, y: 0};
        }
    }
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.state.app.isLoggedIn;
    if (authRequired && !loggedIn) {
        next('/login');
    } else if (!authRequired && loggedIn) {
        next('/');
    } else {
        next()
    }
});

router.beforeEach((to, from, next) => {
    document.title = process.env.VUE_APP_NAME + ' - ' + to.meta.title
    next()
})

export default router
