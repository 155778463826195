export default {
    product: {

        dialog: {

            addProduct: 'Ajouter un produit',
            updateProduct: 'Modifier un produit',

            addBrand: 'Ajouter une marque',
            updateBrand: 'Modifier une marque',

            addCategory: 'Ajouter une catégorie',
            updateCategory: 'Modifier une catégorie',

            addSubCategory: 'Ajouter une sous catégorie',
            updateSubCategory: 'Modifier une sous catégorie',
        },

    }
}
