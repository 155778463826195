<template>
  <div>
    <div class="logo" v-if="!$store.state.app.miniSidebar">
      <img src="../assets/logo.svg">
    </div>
    <div class="min_logo" v-else>
      <img src="../assets/logo-min.svg">
    </div>

    <v-list flat>


      <v-list-item to="/"
                   color="primary"
                   class="side-v-list-item mb-1"
                   :active-class="$lang === 'ar' ? 'aside-active-rtl' : 'aside-active'">
        <v-list-item-icon>
          <home-icon size="18"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.key1') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/campaigns"
                   color="primary"
                   class="side-v-list-item"
                   :active-class="$lang === 'ar' ? 'aside-active-rtl' : 'aside-active'">
        <v-list-item-icon>
          <file-text-icon size="18"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.key2') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/backoffice"
                   color="primary"
                   class="side-v-list-item"
                   :active-class="$lang === 'ar' ? 'aside-active-rtl' : 'aside-active'">
        <v-list-item-icon>
          <AirplayIcon size="18"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.key3') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <v-list-item to="/products"
                   color="primary"
                   class="side-v-list-item"
                   :active-class="$lang === 'ar' ? 'aside-active-rtl' : 'aside-active'">
        <v-list-item-icon>
          <shopping-cart-icon size="18"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.key9') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/databases"
                   color="primary"
                   class="side-v-list-item"
                   :active-class="$lang === 'ar' ? 'aside-active-rtl' : 'aside-active'">
        <v-list-item-icon>
          <database-icon size="18"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.key4') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/users"
                   color="primary"
                   class="side-v-list-item"
                   :active-class="$lang === 'ar' ? 'aside-active-rtl' : 'aside-active'">
        <v-list-item-icon>
          <users-icon size="18"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.key5') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/account"
                   color="primary"
                   class="side-v-list-item"
                   :active-class="$lang === 'ar' ? 'aside-active-rtl' : 'aside-active'">
        <v-list-item-icon>
          <UserIcon size="18"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.key6') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/publicities"
                   color="primary"
                   class="side-v-list-item"
                   :active-class="$lang === 'ar' ? 'aside-active-rtl' : 'aside-active'">
        <v-list-item-icon>
          <ImageIcon size="18"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('sidebar.key10') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>



    </v-list>
  </div>
</template>

<script>
import {
  ShoppingCartIcon,
  AirplayIcon,
  UsersIcon,
  UserIcon,
  HomeIcon,
  DatabaseIcon,
  FileTextIcon,
  ImageIcon
} from 'vue-feather-icons'

export default {
  components: {
    ShoppingCartIcon,
    AirplayIcon,
    UsersIcon,
    UserIcon,
    HomeIcon,
    DatabaseIcon,
    FileTextIcon,
    ImageIcon
  },
}
</script>

<style scoped>

</style>