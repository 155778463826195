export default {
    message: {
        catchError: 'An error has occurred please correct it.',
        succssDelete: 'This item has been deleted successfully.',
        succssStore: 'This item has been added successfully.',
        succssUpdate: 'This item has been updated successfully.',
        copyMessage: 'Copied to clipboard.',
        copy: 'Copy',
        confirmation: 'Confirmation',
        confirmMessage: 'Are you sure you want to delete this item?',
        confirmSaveCampaign: 'Are you sure you want to save this campaign?',
        changeStatus: 'Are you sure you want to change status this item?',
        noDataTitle: 'No data !',
        noDataMessage: 'No matching records found.',
        information: 'Information',
        itemsPerPageText: 'Rows per page',
        all: 'All',
        noResultsText: 'No matching records found',
        excelStore: 'Add by excel file',
        excelStoreMessage: 'Please enter a position for any field in your Excel file.',
        assignment: 'Assignment',
        salepointRes: 'Number of POS found :',
        salepointAss: 'Number of pos affected :',
        searchRes: 'Search result',
        salepoints: 'Salepoints',
        conditionMessage: 'Click the button above to add conditions',
        waitingSave: 'Saving in progress, please wait...',
        confirmSavePublicity: 'Are you sure you want to save this AD ?',
        confirmDeleteLimit: 'Are you sure you want to save this limitation ?'


    }
}
