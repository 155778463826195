export default {
    breadcrumb: {
        create: 'إضافة',
        campaigns: 'الحملات',
        infoPlanning: 'المعلومات والتخطيط',
        investigations: 'التحقيقات',
        tasks: 'المهام',

        analytics: 'التحليلات',
        databases: 'قواعد البيانات',
        products: 'المنتجات',
        brands: 'العلامات ',
        categories: 'الفئات',
        subCategories: 'الفئات الفرعية',
        config: 'الإعدادات',
        types: 'الأنواع',
        areas: 'المناطق',
        clusters: 'المجموعات',
        spplyTypes: 'أنواع التوريد',
        zones: 'النطاق',
        routes: 'الطرق',
        circuits: 'الدوائر',
        backoffice: 'المكتب الخلفي',
        publicities: 'إعلانات',
        publicityAnalyses: 'Statistique publicitaires',

    }
}
