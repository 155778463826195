<template>
  <div>
    <h3 class="mb-5">{{ title }}</h3>
    <v-progress-linear class="my-4"
                       color="primary"
                       height="10"
                       rounded
                       :value="progressValue"></v-progress-linear>
  </div>
</template>

<script>
export default {
  props: ['title', 'progress'],
  data() {
    return {
      progressValue: this.progress,
    }
  },
  mounted() {
    setTimeout(() => {
      this.progressValue += 25
    }, 200)
  },
}
</script>

<style scoped>

</style>