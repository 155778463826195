export default {
    product: {

        dialog: {

            addProduct: 'أضف منتجًا',
            updateProduct: 'تعديل منتج',

            addBrand: 'أضف علامة',
            updateBrand: 'تحديث علامة',

            addCategory: 'أضف فئة',
            updateCategory: 'تحديث فئة',

            addSubCategory: 'أضف فئة فرعية',
            updateSubCategory: 'تحديث فئة فرعية',
        },

    }
}
