<template>
  <div>

    <LangDialog ref="langDialog"/>

    <v-app-bar app :color="!$vuetify.theme.dark ? 'white' : ''" flat class="shadow">
      <v-app-bar-nav-icon @click="toggleSidebar"/>
      <v-btn :disabled="!inCreate()"
             rounded
             depressed large
             color="primary"
             @click="newCampaign">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('btn.nawCampain') }}
      </v-btn>

      <v-spacer/>

      <ChangeLanguage/>

      <v-btn v-if="isFullscreen" icon @click="minimize">
        <MinimizeIcon/>
      </v-btn>
      <v-btn v-else icon @click="maximize">
        <MaximizeIcon/>
      </v-btn>

      <v-badge class="mr-2" overlap content="0" offset-x="22" offset-y="22">
        <v-btn icon>
          <BellIcon/>
        </v-btn>
      </v-badge>

      <v-menu offset-y transition="slide-x-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon class="v-btn--active">
            <v-avatar v-bind="attrs" v-on="on" size="38">
              <v-img :src="require('@/assets/avatar.png')"></v-img>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-card-text>
            <v-row align="center">
              <v-col cols="3">
                <v-avatar size="50">
                  <v-img :src="require('@/assets/avatar.png')"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="9">
                <strong class="text--primary">
                  {{ $store.state.app.user.name }}
                </strong>
                <span class="d-block d-flex">
                  <v-icon small class="mr-1">mdi-email-outline</v-icon> {{ $store.state.app.user.email }}
                </span>
              </v-col>
            </v-row>
            <v-list nav>

              <v-list-item to="/my-account" two-line class="translateY">
                <v-list-item-avatar color="blue" size="35">
                  <v-icon color="white" size="20">mdi-account-cog</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  Mon compte
                  <v-list-item-subtitle>Account settings</v-list-item-subtitle>
                </v-list-item-title>
              </v-list-item>

              <v-list-item to="/my-account" two-line class="translateY">
                <v-list-item-avatar color="blue" size="35">
                  <v-icon color="white" size="20">mdi-cog</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  Configuration
                  <v-list-item-subtitle>App settings</v-list-item-subtitle>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="logout"
                           class="blue translateY">
                <v-list-item-avatar color="blue" size="35">
                  <v-icon color="white">mdi-power</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="white--text">
                  Déconnexion
                </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-card-text>
        </v-card>

      </v-menu>

    </v-app-bar>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import {MaximizeIcon, MinimizeIcon, BellIcon} from 'vue-feather-icons'
import ChangeLanguage from "@/components/ChangeLanguage";

export default {
  components: {MaximizeIcon, MinimizeIcon, BellIcon, ChangeLanguage},
  data() {
    return {
      miniSidebar: this.$store.state.app.miniSidebar,
      isFullscreen: false,
    }
  },
  methods: {
    newCampaign() {
      this.$refs.langDialog.open()
    },
    toggleSidebar() {
      this.miniSidebar = !this.miniSidebar
      this.$store.commit('app/miniSidebar', this.miniSidebar)
    },
    changeTheme() {
      this.$store.commit('app/darkTheme', !this.$vuetify.theme.dark)
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    inCreate() {
      return this.$route.path !== '/campaigns/create/infoPlanning'
          && this.$route.path !== '/campaigns/create/investigations'
          && this.$route.path !== '/campaigns/create/tasks'
    },
    logout() {
      this.$Progress.start()
      HTTP.post('logout').then(() => {
        this.$Progress.finish()
        this.$store.commit('app/user', '')
        this.$store.commit('app/isLoggedIn', false)
        this.$router.go(0)
      }).catch(err => {
        this.$Progress.fail()
        console.log(err)
      })
    },
    maximize() {
      let el = document.documentElement;
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.webkitRequestFullscreen) { /* Safari */
        el.webkitRequestFullscreen();
      } else if (el.msRequestFullscreen) { /* IE11 */
        el.msRequestFullscreen();
      }
      this.isFullscreen = true
    },
    minimize() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
      this.isFullscreen = false
    },
  }
}
</script>

<style scoped>

</style>