<template>
  <div>
    <v-dialog v-model="dialog"
              :max-width="options.width"
              @keydown.esc="cancel">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="text-center">
          <v-icon size="50" :color="options.color">mdi-information</v-icon>
          <h3 class="my-3 text-uppercase">{{ $t('message.information') }}</h3>
          <p> {{ text }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    text: null,
    options: {
      color: 'info',
      width: 350,
    }
  }),
  methods: {
    open(text) {
      this.text = text
      this.dialog = true
    },
    cancel() {
      this.dialog = false
    },
  }
}
</script>