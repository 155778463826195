export default [
    {
        path: '/analytics',
        component: () => import('../views/analytics/index'),
        meta: {
            title: 'Analyses',
        }
    },
    {
        path: '/analytics/results',
        component: () => import('../views/analytics/results'),
        meta: {
            title: 'Analyses - Résultats',
        }
    },
]