export default {
    sidebar: {
        key1: 'Dashboard',
        key2: 'Campaigns',
        key3: 'Backoffice',
        key4: 'Database',
        key5: 'Users',
        key6: 'My account',
        key7: 'Dark mode',
        key8: 'Sign out',
        key9: 'Products',
        key10: 'Publicities',
    },
}
