import Dashboard from "./pages/dashboard/fr";
import Campaigns from "./pages/campaigns/fr";
import Products from "./pages/products/fr";
import Databases from "./pages/databases/fr";
import Backoffice from "./pages/backoffice/fr";
import Buttons from "./buttons/fr";
import Sidebar from "./sidebar/fr";
import Breadcrumbs from "./breadcrumbs/fr";
import Messages from "./messages/fr";
import Fields from "./fields/fr";
import Info from "./info/fr";
import Publicities from "./pages/publicities/fr";


export default {
    //lang
    lang: {
        fr: 'Français',
        en: 'Anglais',
        ar: 'Arabe',
    },
    //hint
    hint: {
        fr: 'Langue française',
        en: 'Langue anglaise',
        ar: 'Langue Arabe',
    },
    //footer
    footer: {
        copyright: '© Droit d\'auteur',
        text: 'Tous droits réservés.',
    },
    ...Dashboard,
    ...Campaigns,
    ...Products,
    ...Databases,
    ...Backoffice,
    ...Buttons,
    ...Sidebar,
    ...Breadcrumbs,
    ...Messages,
    ...Fields,
    ...Info,
    ...Publicities
}