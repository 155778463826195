export default {
    field: {
        name: 'Name',
        description: 'Description',
        isActive: 'Active ?',
        image: 'Image',
        dates: 'Planning',
        search: 'Search...',
        question: 'Question',
        option: 'Option',
        questionType: 'Question type',
        answerType: 'Answer type',
        isModel: 'Save as a model',
        actions: 'Actions',
        products: 'Products',
        product: 'Product',
        brands: 'Brands ',
        brand: 'Brand',
        categories: 'Categories',
        category: 'Category',
        subCategories: 'Sub Categories',
        subCategory: 'Sub Category',
        code: 'Code',
        file: 'File',
        amount: 'Amount',
        phone: 'Phone',
        type: 'Type',
        city: 'City',
        region: 'Region',
        area: 'Area',
        cluster: 'Cluster',
        spplyType: 'Spply type',
        zone: 'Zone',
        route: 'Route',
        circuit: 'Circuit',
        points: 'Points',
        conditions: 'Conditions',
        box: 'Box',
        quantity: 'Quantity',
        startDate: 'Start date',
        endDate: 'End date',
        title: 'Title',
        publicityType: "Ads Type",
        action: 'Action (Link)',
        small: "Small",
        large: "Large",
        limit: "Limit",
        numberAction: "Total"
    }
}
