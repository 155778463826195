import {campaignObject} from "@/store/objects/campaignObject";
import {investigationObject} from "@/store/objects/investigationObject";
import {taskObject} from "@/store/objects/taskObject";

const state = () => ({
    campaign: campaignObject,
    stepp1: 0,
    stepp2: 0,
})
const mutations = {
    selctedLanguages: (state, data) => {
        state.campaign.lang = data
    },

    // resetCampaignObject: (state) => {
    //     state.campaign = JSON.parse(JSON.stringify(campaignObject))
    // },

    /***Validation***/
    toggelStepp1: (state, boolean) => {
        state.stepp1 = boolean
    },
    toggelStepp2: (state, boolean) => {
        state.stepp2 = boolean
    },

    /***Investigations***/
    addInvestigation: (state) => {
        state.campaign.investigations.push(JSON.parse(JSON.stringify(investigationObject)))
    },
    deleteInvestigation: (state, i) => {
        state.campaign.investigations.splice(i, 1)
    },
    duplicateInvestigation: (state, data) => {
        state.campaign.investigations.push(JSON.parse(JSON.stringify(data)))
    },
    setInvestigationSalepointIds: (state, data) => {
        state.campaign.investigations[data.investigationIndex].salepointIds = data.salepointIds
    },

    /***Tasks***/
    addTask: (state, investigationIndex) => {
        state.campaign.investigations[investigationIndex].tasks.push(JSON.parse(JSON.stringify(taskObject)))
    },
    deleteTask: (state, data) => {
        state.campaign.investigations[data.investigationIndex].tasks.splice(data.i, 1)
    },
    duplicateTask: (state, data) => {
        state.campaign.investigations[data.investigationIndex].tasks.push(JSON.parse(JSON.stringify(data.data)))
    },

    /***Questions***/
    addQuestion: (state, data) => {
        state.campaign.investigations[data.investigationIndex].tasks[data.taskIndex].options.push(JSON.parse(JSON.stringify(taskObject.options[0])))
    },
    dulicateQuestion: (state, data) => {
        state.campaign.investigations[data.investigationIndex].tasks[data.taskIndex].options.push(JSON.parse(JSON.stringify(data.option)))
    },
    deleteQuestion: (state, data) => {
        state.campaign.investigations[data.investigationIndex].tasks[data.taskIndex].options.splice(data.optionIndex, 1)
    },

}

export default {
    namespaced: true,
    state,
    mutations
}