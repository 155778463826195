export default {
    product: {

        dialog: {

            addProduct: 'Add un product',
            updateProduct: 'Update product',

            addBrand: 'Add brand',
            updateBrand: 'Update brand',

            addCategory: 'Add une categorie',
            updateCategory: 'Update categorie',

            addSubCategory: 'Add categorie',
            updateSubCategory: 'Update sub categorie',
        },

    }
}
