const state = () => ({
    lang: 'FR',
    user: '',
    isLoggedIn: false,
    darkTheme: false,
    miniSidebar: false,
})
const mutations = {
    lang: (state, data) => {
        state.lang = data
    },
    darkTheme: (state, boolean) => {
        state.darkTheme = boolean
    },
    miniSidebar: (state, boolean) => {
        state.miniSidebar = boolean
    },
    user: (state, data) => {
        state.user = data
    },
    isLoggedIn: (state, boolean) => {
        state.isLoggedIn = boolean
    }
}

export default {
    namespaced: true,
    state,
    mutations
}