export default {
    breadcrumb: {
        create: 'Create',
        campaigns: 'Campaigns',
        infoPlanning: 'Info & planning',
        investigations: 'Investigations',
        tasks: 'Tasks',

        analytics: 'Analytics',
        databases: 'Databases',
        products: 'Products',
        brands: 'Brands ',
        categories: 'Categories',
        subCategories: 'Sub Categories',
        config: 'Settings',
        types: 'Types',
        areas: 'Areas',
        clusters: 'Clusters',
        spplyTypes: 'Spply Types',
        zones: 'Zones',
        routes: 'Routes',
        circuits: 'Circuits',
        backoffice: 'Backoffice',
        publicities: 'Advertisements',
        publicityAnalyses: 'Statistique publicitaires',

    }
}
