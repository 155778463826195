export default {
    field: {
        name: 'Nom',
        description: 'Description',
        isActive: 'Actif ?',
        image: 'Image',
        dates: 'Planification',
        search: 'Recherche...',
        question: 'Question',
        option: 'Option',
        questionType: 'Type de question',
        answerType: 'Type de réponse',
        isModel: 'Enregistrer comme un model',
        actions: 'Actions',
        products: 'Produits',
        product: 'Produit',
        brands: 'Marques',
        brand: 'Marque',
        categories: 'Catégories',
        category: 'Catégorie',
        subCategories: 'Sous Catégories',
        subCategory: 'Sous Catégorie',
        code: 'Code',
        file: 'Fichier',
        amount: 'Montant',
        phone: 'Téléphone',
        type: 'Type',
        city: 'City',
        region: 'Region',
        area: 'Superficie',
        cluster: 'Cluster',
        spplyType: 'Type approvisionnement',
        zone: 'Zone',
        route: 'Route',
        circuit: 'Circuit',
        points: 'Points',
        conditions: 'Conditions',
        box: 'Box',
        quantity: 'Quantité',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        title: 'Titre',
        publicityType: "Type de publicité",
        action: 'Action (Lien)',
        small: "Petite",
        large: "Large",
        limit: "Limit",
        numberAction: "Total"
    }
}
