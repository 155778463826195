<template>
  <div>
    <v-alert
        v-model="alert"
        color="error"
        dark
        text
        dismissible
        icon="mdi-alert-octagon"
        border="left"
    >
      <ul>
        <li v-for="(error,i) in errors" :key="i">{{ error[0] }}</li>
      </ul>
    </v-alert>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['module', 'path', 'data'],
  data() {
    return {
      alert: false,
      errors: [],
    }
  },
  methods: {
    handleValidate() {
      this.$emit('loading', true)
      return HTTP.post('/' + this.module + '/validation/' + this.path, this.data).then(() => {
        this.$emit('loading', false)
        return true
      }).catch((err) => {
        this.alert = true
        this.$emit('loading', false)
        this.$Progress.start()
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.$vuetify.goTo(0)
        console.log(err)
        return false
      })
    }
  }
}
</script>

<style scoped>

</style>