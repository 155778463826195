<template>
  <div>

    <InfoDialog ref="infoDialog"/>

    <v-select :items="questionTypes"
              hide-details
              v-model="selectedQuestionTypeId"
              class="required"
              item-value="id"
              filled
              :label="$t('field.questionType')">

      <template v-slot:selection="data">
        <v-icon :left="$lang!=='ar'" :right="$lang==='ar'">{{ data.item.icon }}</v-icon>
        {{ data.item.name[$lang] }}
      </template>

      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-icon>
            <v-icon>{{ data.item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name[$lang]"></v-list-item-title>
          </v-list-item-content>
        </template>
      </template>

      <v-icon slot="prepend"
              v-if="textInfo"
              @click="$refs.infoDialog.open(textInfo)"
              color="blue">
        mdi-information
      </v-icon>

    </v-select>
  </div>
</template>

<script>
export default {
  props: ['questionTypeId', 'textInfo'],
  data() {
    return {
      currentQuestionTypeId: this.questionTypeId,
    }
  },
  computed: {
    selectedQuestionTypeId: {
      get() {
        return this.currentQuestionTypeId
      },
      set(id) {
        this.currentQuestionTypeId = id;
      }
    },
    questionTypes() {
      return this.$store.state.api.questionTypes
    }
  },
  watch: {
    selectedQuestionTypeId(id) {
      this.$emit('set-question-type-id', id)
    },
    questionTypeId(id) {
      this.selectedQuestionTypeId = id
    }
  }
}
</script>

<style scoped>

</style>