<template>
  <div>
    <v-footer :color="!$vuetify.theme.dark ? 'bg-light' : '#274060'"
              ref="footer">
      <v-col :class="'text-'+$float" cols="12">
        {{ $t('footer.copyright') }} {{ new Date().getFullYear() }}
        <strong class="text-primary">OPUS</strong>
        {{ $t('footer.text') }}
      </v-col>
    </v-footer>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>