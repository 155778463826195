<template>
  <div>
    <v-snackbar color="error"
                :value="$errorMessage"
                bottom
                left
                timeout="4000">
      <v-icon :left="$float !== 'left'"
              :right="$float !== 'right'"
              color="white">mdi-alert-circle-outline
      </v-icon>
      <span v-html="$errorMessage"></span>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  watch: {
    '$errorMessage': function (el) {
      if (el) {
        let _vm = this
        setTimeout(function () {
          _vm.$errorMessage = null
        }, 4000)
      }
    }
  },
}
</script>

<style scoped>

</style>