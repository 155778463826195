export default {
    btn: {
        nawCampain: 'Nouvelle campagne',
        next: 'Suivant',
        back: 'Retour',
        ok: 'OK',
        cancel: 'Annuler',
        save: 'Enregistrer',
        delete: 'Supprimer',
        update: 'Modifier',
        duplicate: 'Dupliquer',
        add: 'Ajouter',
        link: 'Afficher',
        filter: 'Filtrage',
        refresh: 'Actualiser',
        export: 'Exporter',
        drag: 'Glisser',
        uploadImagePdf: 'Sélectionner une fichier pdf ou image',
        show: 'Voire',
        rename: 'Renommer',
        download: 'Télécharger',
        excel: 'Excel',
        search: 'Rechercher',
        validate: 'Enregistrer le résultat',
        addConditions: 'Ajouter des conditions',
        settings: 'Paramètres'
    }
}
