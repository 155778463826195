<template>
  <div>
    <v-navigation-drawer app
                         color="#274060"
                         dark
                         :right="$lang==='ar'"
                         :mini-variant="$store.state.app.miniSidebar">
      <sidebar/>

      <template v-slot:append>

        <v-list flat>

          <v-list-item class="side-v-list-item"
                       @click="[changeTheme(),darkThem = !darkThem]">
            <v-list-item-icon>
              <MoonIcon v-if="darkThem" size="18"/>
              <SunIcon v-else size="18"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('sidebar.key7') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch hide-details readonly
                        v-model="darkThem"
                        inset dense></v-switch>
            </v-list-item-action>
          </v-list-item>

          <v-list-item class="side-v-list-item" @click="logout">
            <v-list-item-icon>
              <LogOutIcon size="18"/>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('sidebar.key8') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </template>

    </v-navigation-drawer>
    <navbar/>
  </div>
</template>

<script>
import Sidebar from "@/navigation/Sidebar";
import Navbar from "@/navigation/Navbar";
import {HTTP} from "@/http-common";
import {
  LogOutIcon,
  SunIcon,
  MoonIcon,
} from 'vue-feather-icons'

export default {
  components: {Sidebar, Navbar, LogOutIcon, SunIcon, MoonIcon},
  data() {
    return {
      darkThem: this.$vuetify.theme.dark
    }
  },
  methods: {
    logout() {
      this.$Progress.start()
      HTTP.post('logout').then(() => {
        this.$Progress.finish()
        this.$store.commit('app/user', '')
        this.$store.commit('app/isLoggedIn', false)
        this.$router.go(0)
      }).catch(err => {
        this.$Progress.fail()
        console.log(err)
      })
    },
    changeTheme() {
      this.$store.commit('app/darkTheme', !this.$vuetify.theme.dark)
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
}
</script>
