export const publicityObject = {

    lang: [],
    name: {
        fr: null,
        en: null,
        ar: null,
    },
    description: {
        fr: null,
        en: null,
        ar: null,
    },
    dates: [],
    image: null,
    isActive: true,
    action: null,
    type: null,
    salepointIds: []

}