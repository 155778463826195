export const taskObject = {
    file: null,
    question: {
        fr: null,
        en: null,
        ar: null,
    },
    answerTypeId: 1,
    questionTypeId: 1,
    options: [{
        fr: null,
        en: null,
        ar: null,
    }],
    conditions: []
};
