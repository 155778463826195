<template>
  <div>

    <v-btn color="primary"
           outlined rounded
           depressed
           @click="open">
      <v-icon left>mdi-plus-circle</v-icon>
      {{ $t('btn.excel') }}
    </v-btn>

    <v-dialog v-model="dialog" width="400">
      <form @submit.prevent="save">
        <v-card>
          <v-card-title>
            {{ $t('message.excelStore') }}
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider/>

          <v-card-text class="pa-4">

            <v-alert color="info" text dense>
              {{ $t('message.excelStoreMessage') }}
              <strong class="d-block">(A,B,C...)</strong>
            </v-alert>

            <v-text-field :label="$t('field.code')"
                          v-if="item === 'products'"
                          class="required"
                          :error-messages="errors.code_position"
                          v-model="form.code_position"/>

            <v-text-field :label="$t('field.name')"
                          class="required"
                          :error-messages="errors.name_position"
                          v-model="form.name_position"/>

            <v-text-field :label="$t('field.brand')"
                          v-if="item === 'products'"
                          class="required"
                          :error-messages="errors.brand_position"
                          v-model="form.brand_position"/>

            <v-text-field :label="$t('field.category')"
                          v-if="item === 'products' || item === 'subCategories' "
                          class="required"
                          :error-messages="errors.category_position"
                          v-model="form.category_position"/>


            <v-text-field :label="$t('field.subCategory')"
                          v-if="item === 'products'"
                          class="required"
                          :error-messages="errors.sub_category_name_position"
                          v-model="form.sub_category_name_position"/>


            <v-text-field :label="$t('field.description')"
                          :error-messages="errors.description_position"
                          v-model="form.description_position"/>

            <v-file-input ref="upload"
                          hide-details="auto"
                          filled rounded
                          class="required"
                          prepend-icon=""
                          :error-messages="errors.file"
                          :label="$t('field.file')"
                          @change="uploadFile" show-size chips small-chips>
              <v-icon slot="prepend-inner"
                      color="blue">
                mdi-cloud-upload
              </v-icon>
            </v-file-input>

          </v-card-text>

          <v-divider/>

          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary"
                   :loading="isLoading"
                   type="submit"
                   depressed>
              <v-icon left>mdi-content-save</v-icon>
              {{ $t('btn.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['item'],
  data: () => ({
    dialog: false,
    isLoading: false,
    form: {
      file: ''
    },
    errors: {},
  }),
  methods: {
    open() {
      this.errors = {}
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    uploadFile(file) {
      this.form.file = file
    },
    save() {
      this.errors = {}
      this.$Progress.start()
      this.isLoading = true

      let url = this.item + "/store-by-excel"


      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key])
      })

      HTTP.post(url, formData).then(() => {
        this.$emit('refresh')
        this.isLoading = false

        this.$successMessage = this.$i18n.t('message.succssStore')
        this.close()
        this.$refs.upload.reset()
        this.form = {}
        this.$Progress.finish()
      }).catch((err) => {
        this.isLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    }
  }
}
</script>