import {investigationObject} from "@/store/objects/investigationObject";

export const campaignObject = {

    lang: [],
    name: {
        fr: null,
        en: null,
        ar: null,
    },
    description: {
        fr: null,
        en: null,
        ar: null,
    },
    dates: [],
    image: null,
    isActive: true,

    investigations: [investigationObject],
}