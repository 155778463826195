export default [
    {
        path: '/databases',
        component: () => import('../views/databases/index'),
        meta: {
            title: 'BDD',
        }
    },
    {
        path: '/databases/:id/show',
        component: () => import('../views/databases/show/index'),
        meta: {
            title: 'BDD',
        }
    },
    {
        path: '/databases/config',
        component: () => import('../views/databases/config/index'),
        meta: {
            title: 'BDD - Config',
        }
    },
]