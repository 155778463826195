export default {
    btn: {
        nawCampain: 'حملة جديدة',
        next: 'التالي',
        back: 'رجوع',
        ok: 'نعم',
        cancel: 'إلغاء',
        save: 'حفظ',
        delete: 'حذف',
        update: 'تحديث',
        duplicate: 'نسخ',
        add: 'إضافة',
        link: 'عرض',
        filter: 'الفلتره',
        refresh: 'تحديث',
        export: 'تحديث',
        drag: 'جر',
        uploadImagePdf: 'حدد ملف pdf أو صورة',
        show: 'شاهد المزيد',
        rename: 'إعادة التسمية',
        download: 'تحميل',
        excel: 'اكسل',
        search: 'بحث',
        validate: 'احفظ النتيجة',
        addConditions: 'إضافة المنتجات',
        settings: 'الإعدادات'
        
    }
}
