export default {
    message: {
        catchError: 'Une erreur ç\'est produit veuillez le corriger.',
        succssDelete: 'Cet élément a été supprimé avec succès.',
        succssStore: 'Cet élément a été ajouté avec succès.',
        succssUpdate: 'Cet élément a été modifié avec succès.',
        copyMessage: 'Copié dans le presse-papier.',
        copy: 'Copie',
        confirmation: 'Confirmation',
        confirmMessage: 'Êtes-vous sûr de bien vouloir supprimer cet élément ?',
        confirmSaveCampaign: 'Voulez-vous vraiment enregistrer cette campagne ?',
        changeStatus: 'Êtes-vous sûr de bien vouloir changer le statut de cet élément?',
        noDataTitle: 'Pas de données !',
        noDataMessage: 'Aucun enregistrement correspondant trouvé.',
        information: 'Information',
        itemsPerPageText: 'Lignes par page',
        all: 'Toute',
        noResultsText: 'Aucun enregistrements correspondants trouvés',
        excelStore: 'Ajouter par un fichier excel',
        excelStoreMessage: 'Veuillez saisir une position pour n\'importe quel champ de votre fichier Excel.',
        assignment: 'Affectation',
        salepointRes: 'Nombre des POS trouvés :',
        salepointAss: 'Nombre des POS concernés :',
        searchRes: 'Résultat de la recherche',
        salepoints: 'POS',
        conditionMessage: 'Cliquez sur le bouton ci-dessus pour ajouter des conditions',
        waitingSave: 'Enregistrement en cours, Veuillez patienter...',
        confirmSavePublicity: 'Voulez-vous vraiment enregistrer cette publicité ?',
        confirmDeleteLimit: 'Voulez-vous vraiment enregistrer cette limitation ?'
    }
}
