export default {
    breadcrumb: {
        create: 'Créer',
        campaigns: 'Campagnes',
        infoPlanning: 'Info & Planning',
        investigations: 'Enquêtes',
        tasks: 'Tâches',

        analytics: 'Analyses',
        databases: 'Base de donneés',
        products: 'Produits',
        brands: 'Marques ',
        categories: 'Catégories',
        subCategories: 'Sous Catégories',
        config: 'Paramètres',
        types: 'Types',
        areas: 'Superficies',
        clusters: 'Clusters',
        spplyTypes: 'Types approvisionnement',
        zones: 'zones',
        routes: 'Routes',
        circuits: 'Circuits',
        backoffice: 'Backoffice',
        publicities: 'Publicités',
        publicityAnalyses: 'Statistique publicitaires',
    }
}
