import Dashboard from "./pages/dashboard/ar";
import Campaigns from "./pages/campaigns/ar";
import Products from "./pages/products/ar";
import Databases from "./pages/databases/ar";
import Backoffice from "./pages/backoffice/ar";
import Buttons from "./buttons/ar";
import Sidebar from "./sidebar/ar";
import Breadcrumbs from "./breadcrumbs/ar";
import Messages from "./messages/ar";
import Fields from "./fields/ar";
import Info from "./info/ar";
import Publicities from "./pages/publicities/fr";

export default {
    //lang
    lang: {
        fr: 'الفرنسية',
        en: 'الإنجليزية',
        ar: 'العربية',
    },
    //hint
    hint: {
        fr: 'اللغة الفرنسية',
        en: 'اللغة الإنجليزية',
        ar: 'اللغة العربية',
    },
    //footer
    footer: {
        copyright: '© حقوق النشر',
        text: 'جميع الحقوق محفوظة.',
    },
    ...Dashboard,
    ...Campaigns,
    ...Products,
    ...Databases,
    ...Backoffice,
    ...Buttons,
    ...Sidebar,
    ...Breadcrumbs,
    ...Messages,
    ...Fields,
    ...Info,
    ...Publicities,
}