<template>
  <div>

    <v-btn depressed
           fab
           x-large
           class="transform-none"
           :loading="loading[index]"
           :disabled="loading[index]"
           @click="onButtonClick">
      <v-icon>mdi-cloud-upload</v-icon>
    </v-btn>

    <div class="d-block" v-if="path">
      <v-chip small color="primary" dark @click="displayFile(path)"> {{ path }}</v-chip>
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on"
                 @click="$emit('file', null)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </div>


    <input
        ref="uploader"
        class="d-none"
        type="file"
        accept="image/*"
        @change="handleUploadFile"
    >
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['type', 'path', 'index', 'total'],
  data() {
    return {
      errors: [],
    }
  },
  methods: {
    onButtonClick() {
      window.addEventListener('focus', () => {
      }, {once: true})
      this.$refs.uploader.click()
    },
    handleUploadFile(file) {
      this.isLoading()
      let formData = new FormData()
      formData.append('file', file.target.files[0])
      HTTP.post('/upload/' + this.type, formData).then((res) => {
        this.stopLoading()
        this.$emit('file', res.data)
      }).catch((err) => {
        this.stopLoading()
        this.errors = err.response.data.errors
        this.$Progress.start()
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    },
    isLoading() {
      this.$forceUpdate()
      this.loading[this.index] = true
    },
    stopLoading() {
      this.$forceUpdate()
      this.loading[this.index] = false
    },
    displayFile(file) {
      window.open(this.$fileUrl + file, '_blanc')
    }
  },
  computed: {
    loading() {
      let loading = []
      for (let step = 0; step < this.total; step++) {
        loading.push(false)
      }
      return loading
    }
  }
}
</script>

<style scoped>

</style>