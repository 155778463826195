<template>
  <v-tooltip top color="primary">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs"
             :color="color ? color : ''"
             @click="download"
             v-on="on" icon>
        <v-icon>mdi-download-outline</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('btn.export') }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ['link', 'color'],
  methods: {
    download() {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.app.user.token
      let userId = this.$store.state.app.user.id
      let DownloadURL = baseUrl + '/api/' + this.link + '?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    }
  }
}
</script>
<style scoped>
</style>