export default {
    database: {
        dialog: {

            addDatabase: 'Add DB',
            updateDatabase: 'Add DB',
            renameDatabase: 'Rename DB',

            addArea: 'Add area',
            updateArea: 'Update area',

            addCluster: 'Add cluster',
            updateCluster: 'Update cluster',

            addCircuit: 'Add circuit',
            updateCircuit: 'Update circuit',

            addZone: 'Add zone',
            updateZone: 'Update zone',

            addRoute: 'Add route',
            updateRoute: 'Update route',

            addSupplyType: 'Add supply type',
            updateSupplyType: 'Update supply type',
        },
    }
}
