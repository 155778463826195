export default {
    campaign: {
        //Page title
        title: {
            key1: 'Info & planification',
            key2: 'Enquêtes',
            key3: 'Tâches',
        }
    }
}
