import store from "@/store";

export const func = {
    //Progress color
    progressColor: (el) => {
        if (el <= 30) {
            return 'red'
        }
        if (el > 30 && el <= 60) {
            return 'yellow'
        }
        if (el > 60 && el <= 90) {
            return 'primary'
        }
        if (el > 90 && el <= 100) {
            return 'success'
        }
    },

    //Check campaign languages whene create new
    langNC(lang) {
        return store.state.campaign.campaign.lang.includes(lang)
    },

    //Set name by lang
    setName(name, lang) {
        if (lang.includes('fr')) {
            return name['fr']
        } else {
            if (lang.lang.includes('en')) {
                return name['en']
            } else {
                return name['ar']
            }
        }
    },

    //Percent
    percent(totalItem, total) {

        Number.prototype.round = function (places) {
            return +(Math.round(this + "e+" + places) + "e-" + places);
        }

        return totalItem > 0 ? (totalItem * 100 / total).round(2) : 0
    },

    langNPub(lang) {
        return store.state.publicity.publicity.lang.includes(lang)
    },
}