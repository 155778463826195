<template>
  <div>
    <v-file-input :label="$t('field.image')"
                  @change="handleUploadFile"
                  chips
                  :clearable="false"
                  small-chips
                  show-size
                  :prepend-icon="null"
                  @click="errors && errors[type] ? errors[type] = '' : false"
                  :error-messages="errors[type]">
      <v-icon slot="prepend-inner"
              color="blue">
        mdi-cloud-upload
      </v-icon>
    </v-file-input>

    <div v-if="path"
         class="d-flex justify-space-between pa-3 mb-2 rounded"
         :class="$store.state.app.darkTheme ? 'secondary lighten-1' : 'grey lighten-3'">
      <v-avatar size="40"
                @click="[image = [{src: $fileUrl + path}],visible = true]">
        <v-img :src="$fileUrl + path" class="rounded zoom-pointer"/>
      </v-avatar>
      <v-spacer/>
      <v-icon color="success">mdi-check-circle</v-icon>
    </div>

    <vue-easy-lightbox escDisabled
                       moveDisabled
                       :visible="visible"
                       :imgs="image"
                       :index="index"
                       @hide="visible = false"></vue-easy-lightbox>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['type', 'path'],
  data() {
    return {
      errors: [],

      /**Lightbox**/
      image: '',
      visible: false,
      index: 0,
    }
  },
  methods: {
    handleUploadFile(file) {
      this.$emit('loading', true)
      let formData = new FormData()
      formData.append(this.type, file)
      HTTP.post('/upload/' + this.type, formData).then((res) => {
        this.$emit('loading', false)
        this.$emit('file', res.data)
        
      }).catch((err) => {
        this.$emit('loading', false)
        this.$Progress.start()
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>

</style>