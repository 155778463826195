<template>
  <div>

    <div class="text-center">
      <v-btn color="blue"
             dark
             outlined
             large
             rounded
             depressed
             @click="open">
        <v-icon left>mdi-plus-circle</v-icon>
        {{ $t('btn.addConditions') }}
      </v-btn>

      <div class="py-2" v-show="!conditionIds.length">
        <v-avatar tile size="150">
          <v-img :src="require('@/assets/no-data.svg')"/>
        </v-avatar>
        <p class="fs-16 py-5">
          {{$t('message.conditionMessage')}}
        </p>
      </div>

    </div>


    <v-dialog v-model="dialog" max-width="800" scrollable persistent>
      <v-card>
        <v-card-title>
          {{ $t('message.conditions') }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-4">

          <span class="fs-16">
            <v-icon left>mdi-filter-outline</v-icon>
            {{ $t('field.brands') }}
          </span>
          <v-chip-group v-model="filter.brandIds"
                        column
                        multiple>
            <v-chip filter
                    color="blue"
                    dark
                    text-color="white"
                    v-for="item in $store.state.api.brands"
                    :key="item.id"
                    :value="item.id">
              {{ item.name }}
            </v-chip>
          </v-chip-group>

          <div class="my-3">

            <span class="fs-16">
              <v-icon left>mdi-filter-outline</v-icon>
                {{ $t('field.categories') }}
            </span>

            <v-chip-group v-model="filter.categoryIds"
                          column
                          multiple>
              <v-chip filter
                      color="blue"
                      dark
                      text-color="white"
                      v-for="item in $store.state.api.categories"
                      :key="item.id"
                      :value="item.id">
                {{ item.name }}
              </v-chip>
            </v-chip-group>
          </div>


          <span class="fs-16">
            <v-icon left>mdi-filter-outline</v-icon>
            {{ $t('field.subCategories') }}
          </span>

          <v-chip-group v-model="filter.subCategoryIds"
                        column
                        multiple>
            <v-chip filter
                    color="blue"
                    dark
                    text-color="white"
                    v-for="item in $store.state.api.subCategories"
                    :key="item.id"
                    :value="item.id">
              {{ item.name }}
            </v-chip>
          </v-chip-group>


          <v-text-field dense
                        hide-details
                        class="my-5"
                        prepend-inner-icon="mdi-magnify"
                        filled rounded
                        v-model.trim="search"
                        :placeholder="$t('field.search')"></v-text-field>

          <v-item-group v-if="products.length"
                        multiple
                        v-model="selectedConditionIds">

            <v-row>

              <v-col cols="3"
                     v-for="item in products"
                     :key="item.id"
                     class="d-flex translateY">

                <v-item v-slot="{ active, toggle }"
                        :value="item.id">
                  <v-card outlined
                          :class="active ? 'border--blue' : ''"
                          class="flex flex-column border-2">
                    <div @click="toggle" class="cursor-pointer">

                      <v-scroll-y-transition>
                        <v-btn
                            depressed
                            v-if="active"
                            color="blue"
                            fab dark
                            small absolute
                            top right>
                          <v-icon dark>mdi-check</v-icon>
                        </v-btn>
                      </v-scroll-y-transition>

                      <v-img v-if="item.image" :src="$fileUrl + item.image" height="120"/>

                      <v-img v-else :src="require('@/assets/no-image.png')" height="120"/>

                      <div class="text-center pa-4">
                        <span>{{ item.name }}</span>
                        <strong class="d-block mt-2">
                          <span class="text-decoration-underline">Code</span> <br> {{ item.code }}
                        </strong>
                      </div>

                    </div>
                  </v-card>
                </v-item>

              </v-col>
            </v-row>
          </v-item-group>

          <NoResult v-else size="150"/>

        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 dark
                 depressed
                 @click="save">
            <v-icon left>mdi-check</v-icon>
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  props: ['conditionIds'],
  data() {
    return {
      dialog: false,
      items: [],
      currentConditionIds: this.conditionIds,
      filter: {
        brandIds: [],
        categoryIds: [],
        subCategoryIds: [],
      },
      search: null
    }
  },
  methods: {
    open() {
      this.currentConditionIds = this.conditionIds
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.dialog = false
      let conditions = []

      let _vm = this
      this.selectedConditionIds.map(function (id) {
        let product = _vm.$store.state.api.products.find(el => el.id === id)
        conditions.push({
          id: id,
          code: product.code,
          name: product.name,
          image: product.image,
          quantity: 1,
          isBox: false,
        })
      })
      this.$emit('set-conditions', conditions)
    },

  },

  computed: {
    products: {
      get() {
        return this.$store.state.api.products.filter(el => {

          if (this.search) {
            return el.name.toLowerCase().includes(this.search.toLowerCase())
                || el.code.toLowerCase().includes(this.search.toLowerCase())
          }

          if (this.filter.brandIds.length) {
            return this.filter.brandIds.includes(el.brand_id)
          }

          if (this.filter.categoryIds.length) {
            return this.filter.categoryIds.includes(el.category_id)
          }

          if (this.filter.subCategoryIds.length) {
            return this.filter.subCategoryIds.includes(el.sub_category_id)
          }

          return el

        });
      },
    },
    selectedConditionIds: {
      get() {
        return this.currentConditionIds
      },
      set(id) {
        this.currentConditionIds = id;
      }
    },
  },
}
</script>

<style scoped>

</style>