<template>
  <div>
    <v-card class="rounded-lg mb-4 shadow">
      <v-breadcrumbs :items="setBreadcrumbs" large class="pt-3 pb-3">
        <template v-slot:divider>
          <v-icon v-html="'mdi-chevron-'+$float"></v-icon>
        </template>
        <template v-slot:item="{ item }">

          <v-breadcrumbs-item :to="item.to" exact
                              :disabled="item.disabled">

            <div v-if="!item.text">
              <v-avatar color="primary"
                        class="cursor-pointer"
                        size="40" @click="$router.push('/')">
                <v-icon color="white" size="20">mdi-home-variant-outline
                </v-icon>
              </v-avatar>
            </div>

            <div v-else>
              {{ $t(item.text) }}
            </div>

          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['breadcrumbs'],
  data() {
    return {
      setBreadcrumbs: [
        {text: null, disabled: false, to: '/'}
      ]
    }
  },
  mounted() {
    this.setBreadcrumbs = [this.setBreadcrumbs, ...this.breadcrumbs]
  },
}
</script>
