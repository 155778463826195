export default {
    campaign: {
        //Page title
        title: {
            key1: 'المعلومات والتخطيط',
            key2: 'التحقيقات',
            key3: 'المهام',
        }
    }
}
