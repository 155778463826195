import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import FR from './fr'
import EN from './en'
import AR from './ar'
import store from "@/store";

export default new VueI18n({
    locale: store.state.app.lang, // set locale
    messages: {
        FR,
        EN,
        AR
    },
})
