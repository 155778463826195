<template>
  <v-dialog v-model="dialog"
            :max-width="options.width"
            @keydown.esc="cancel">
    <v-card>
      <v-card-text class="pa-4 text-center">
        <v-icon size="100" :color="options.color">mdi-information-outline</v-icon>
        <h2 class="my-5 text-uppercase">{{ title ? title : $t('message.confirmation') }}</h2>
        <p class="fs-16"> {{ message ? message : $t('message.confirmMessage') }}</p>

        <div v-html="others"></div>

        <div class="mt-8 mb-2">
          <v-btn text
                 color="primary"
                 @click.native="cancel">
            <v-icon left>mdi-close</v-icon>
            {{ options.cancelText ? options.cancelText : $t('btn.cancel') }}
          </v-btn>
          <v-btn color="primary"
                 depressed
                 @click.native="agree"
                 :class="$lang==='ar'? 'mr-2' :'ml-2'">
            <v-icon left>mdi-check</v-icon>
            {{ options.agreeText ? options.agreeText : $t('btn.ok') }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    others: null,
    options: {
      agreeText: null,
      cancelText: null,
      color: 'primary',
      width: 410,
    }
  }),
  methods: {
    open(title, message, options,others) {
      this.others = others
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>