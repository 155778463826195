import Vue from 'vue'
import App from './App.vue'
import vuetify from "./plugins/vuetify"
import router from "./routes"
import store from './store/index'
import './global'
import './directives'
import './filters'
import VueMoment from "vue-moment";
import moment from 'moment-timezone'
import {func} from './functions'
import VueEasyLightbox from 'vue-easy-lightbox'
import * as VueGoogleMaps from 'vue2-google-maps'
import i18n from './lang'
import VueProgressBar from 'vue-progressbar'

/** Components **/
import draggable from 'vuedraggable'
import LangDialog from "@/components/LangDialog"
import LangDialogPublicity from "@/components/LangDialogPublicity"

import ConfirmDialog from "@/components/ConfirmDialog"
import InfoDialog from "@/components/InfoDialog"
import Breadcrumbs from "@/components/Breadcrumbs"
import NoResult from "@/components/NoResult"
import CreateProgress from "@/components/CreateProgress"
import UploadFile from "@/components/form/UploadFile";
import DateRange from "@/components/form/DateRange";
import AnswerTypes from "@/components/form/AnswerTypes";
import QuestionTypes from "@/components/form/QuestionTypes";
import FormValidation from "@/components/FormValidation";
import Copy from "@/components/Copy";
import SwitchStatus from "@/components/actions/SwitchStatus";
import Delete from "@/components/actions/Delete";
import Update from "@/components/actions/Update";
import Refresh from "@/components/actions/Refresh";
import Export from "@/components/actions/Export";
import IconUploadFile from "@/components/form/IconUploadFile";
import SalepointDialog from "@/components/SalepointDialog";
import ExcelStoreDialog from "@/components/ExcelStoreDialog";
import Condition from "@/components/questionTypes/Condition";
import BtnLink from "@/components/actions/BtnLink";
import TextLink from "@/components/actions/TextLink";
import PublicityType from "@/components/form/PublicityType";
import Btn from "@/components/actions/Btn";

Vue.component('LangDialog', LangDialog)
Vue.component('LangDialogPublicity', LangDialogPublicity)

Vue.component('SalepointDialog', SalepointDialog)
Vue.component('ExcelStoreDialog', ExcelStoreDialog)
Vue.component('draggable', draggable)
Vue.component('ConfirmDialog', ConfirmDialog)
Vue.component('InfoDialog', InfoDialog)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('NoResult', NoResult)
Vue.component('CreateProgress', CreateProgress)
Vue.component('UploadFile', UploadFile)
Vue.component('IconUploadFile', IconUploadFile)
Vue.component('DateRange', DateRange)
Vue.component('Condition', Condition)
Vue.component('QuestionTypes', QuestionTypes)
Vue.component('AnswerTypes', AnswerTypes)
Vue.component('FormValidation', FormValidation)
Vue.component('Copy', Copy)
Vue.component('SwitchStatus', SwitchStatus)
Vue.component('Delete', Delete)
Vue.component('Update', Update)
Vue.component('BtnLink', BtnLink)
Vue.component('TextLink', TextLink)
Vue.component('Refresh', Refresh)
Vue.component('Export', Export)
Vue.component('PublicityType', PublicityType)
Vue.component('Btn', Btn)

/** Components **/


require('moment/locale/fr')

Vue.prototype.$func = func
Vue.config.productionTip = false

const options = {
    color: '#FFAB02',
    failedColor: '#FF2442',
    thickness: '3px',
}
Vue.use(VueProgressBar, options)
Vue.use(VueEasyLightbox)
Vue.use(VueMoment, {moment})
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_KEY,
        libraries: 'places',
    },
})

new Vue({
    render: h => h(App),
    store,
    i18n,
    router,
    vuetify,
}).$mount('#app')
