export default [
    {
        path: '*',
        component: () => import('../views/notFound'),
        meta: {
            title: 'Page not found'
        }
    },
    {
        path: '/login',
        component: () => import('../views/auth/login'),
        meta: {
            title: 'Connexion'
        }
    },
]