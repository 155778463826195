import Vue from 'vue'
import Vuetify from 'vuetify'
import fr from 'vuetify/es5/locale/fr';
import en from 'vuetify/es5/locale/en';
import ar from 'vuetify/es5/locale/ar';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '../style/main.css'
import store from "@/store";

Vue.use(Vuetify)

let lang = store.state.app.lang

const opts = {
    theme: {
        dark: store.state.app.darkTheme,
        themes: {
            light: {
                primary: '#FFAB02',
                secondary: '#173156',
                error: '#FF2442',
                success: '#79D70F',
                indigo: '#813EF9',
                grey: '#a7a6a6',
            },
            dark: {
                primary: '#FFAB02',
                secondary: '#173156',
                error: '#FF2442',
                success: '#79D70F',
                indigo: '#813EF9',
                grey: '#a7a6a6',
            },
        },
    },
    lang: {
        locales: lang === 'fr' ? {fr} : lang === 'en' ? {en} : {ar},
        current: lang,
    },
    icons: {
        iconfont: 'mdi',
    },
    rtl: store.state.app.lang === 'AR'
}
export default new Vuetify(opts)
