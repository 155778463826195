import Vue from "vue";
import store from "@/store";

let currantData = new Vue({
    data: {
        $successMessage: '',
        $errorMessage: '',
        $infoMessage: '',
        $lang: store.state.app.lang,
        $float: store.state.app.lang === 'AR' ? 'left' : 'right',
        $fileUrl: process.env.VUE_APP_FILE_URL,
        $perPage: [10, 20, 50, 100],
        $totalPerPage: 10,
    },
});
Vue.mixin({
    computed: {
        $successMessage: {
            get: function () {
                return currantData.$data.$successMessage
            },
            set: function (data) {
                currantData.$data.$successMessage = data;
            }
        },
        $errorMessage: {
            get: function () {
                return currantData.$data.$errorMessage
            },
            set: function (data) {
                currantData.$data.$errorMessage = data;
            }
        },
        $infoMessage: {
            get: function () {
                return currantData.$data.$infoMessage
            },
            set: function (data) {
                currantData.$data.$infoMessage = data;
            }
        },
        $lang: {
            get: function () {
                return currantData.$data.$lang.toLowerCase()
            },
            set: function (data) {
                currantData.$data.$lang = data.toLowerCase();
            }
        },
        $float: {
            get: function () {
                return currantData.$data.$float
            },
            set: function (data) {
                currantData.$data.$float = data;
            }
        },
        $fileUrl: {
            get: function () {
                return currantData.$data.$fileUrl
            }
        },
        $perPage: {
            get: function () {
                return currantData.$data.$perPage
            }
        },
        $totalPerPage: {
            get: function () {
                return currantData.$data.$totalPerPage
            }
        },
    }
});