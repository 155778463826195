<template>
  <span>
    <ConfirmDialog ref="confirmDialog"/>
    <v-switch
        :label="label"
        hide-details
        class="ma-0 pa-0"
        :input-value="value"
        readonly
        @click="handleSwitch"
        color="blue"></v-switch>
  </span>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['url', 'id', 'value', 'label'],
  methods: {
    async handleSwitch() {
      if (await this.$refs.confirmDialog.open(null, this.$i18n.t('message.changeStatus'))) {
        this.$Progress.start()
        this.$emit('overlay', true)
        HTTP.post(this.url, {
          id: this.id
        }).then(() => {
          this.$Progress.finish()
          this.$emit('overlay', false)
          this.$successMessage = this.$i18n.t('message.succssUpdate')
          this.$emit('refresh')
        }).catch(err => {
          this.$Progress.fail()
          this.$errorMessage = this.$i18n.t('message.catchError')
          this.$emit('overlay', false)
          console.log(err)
        })
      }
    },
  }
}
</script>

<style scoped>

</style>