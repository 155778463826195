<template>
  <span>
    <ConfirmDialog ref="confirmDialog"/>
    <v-tooltip top color="primary">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on"
               :color="color ? color : 'blue'"
               icon
               @click="handleDelete">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('btn.delete') }}</span>
    </v-tooltip>
  </span>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['url', 'id', 'color'],
  methods: {
    async handleDelete() {
      if (await this.$refs.confirmDialog.open()) {
        this.$Progress.start()
        this.$emit('overlay', true)
        HTTP.post(this.url, {
          id: this.id
        }).then(() => {
          this.$Progress.finish()
          this.$emit('overlay', false)
          this.$successMessage = this.$i18n.t('message.succssDelete')
          this.$emit('refresh')
        }).catch(err => {
          this.$Progress.fail()
          this.$errorMessage = this.$i18n.t('message.catchError')
          this.$emit('overlay', false)
          console.log(err)
        })
      }
    },
  }
}
</script>

<style scoped>

</style>