<template>
  <span>
    <v-tooltip top color="primary">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on"
               :color="color ? color : 'blue'"
               icon
               @click="update">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ rename ? $t('btn.rename') : $t('btn.update') }}</span>
    </v-tooltip>
  </span>
</template>

<script>

export default {
  props: ['color', 'rename'],
  methods: {
    update() {
      this.$emit('update')
    }
  }
}
</script>

<style scoped>

</style>