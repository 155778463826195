<template>
  <v-dialog v-model="dialog"
            width="350">
    <v-card>
      <v-card-title>
        <v-icon color="blue" left>mdi-earth</v-icon>
        Choisir les langues
        <v-spacer/>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-4">
        <v-list>
          <v-list-item-group v-model="publicity.lang" multiple color="primary">
            <v-list-item
                v-for="(lang, i) in languages"
                :key="i"
                :value="lang.value"
            >
              <template v-slot:default="{ active }">

                <v-list-item-avatar size="35">
                  <v-img :src="require('@/assets/'+(lang.value).toUpperCase()+'.png')"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="$t(lang.key)"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox
                      :input-value="active"
                      color="primary"
                  ></v-checkbox>
                </v-list-item-action>
              </template>

            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-btn block
               :disabled="!publicity.lang.length"
               @click="newCampaign"
               color="primary"
               depressed>
          {{ $t('btn.next') }}
          <v-icon right v-html="'mdi-arrow-'+$float"></v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    languages: [
      {key: 'lang.fr', value: 'fr'},
      {key: 'lang.en', value: 'en'},
      {key: 'lang.ar', value: 'ar'}
    ],
  }),
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    newCampaign() {
      this.close()
      // this.$store.commit('publicity/resetCampaignObject')
      this.$store.commit('publicity/selectedLanguages', this.publicity.lang)
      this.$router.push('/publicities/create/infoPlanning')
    }
  },
  computed: {
    publicity() {
      return this.$store.state.publicity.publicity
    }
  }
}
</script>