import Dashboard from "./pages/dashboard/en";
import Campaigns from "./pages/campaigns/en";
import Products from "./pages/products/en";
import Databases from "./pages/databases/en";
import Backoffice from "./pages/backoffice/en";
import Buttons from "./buttons/en";
import Sidebar from "./sidebar/en";
import Breadcrumbs from "./breadcrumbs/en";
import Messages from "./messages/en";
import Fields from "./fields/en";
import Info from "./info/en";
import Publicities from "./pages/publicities/fr";

export default {
    //lang
    lang: {
        fr: 'Franch',
        en: 'English',
        ar: 'Arab',
    },
    //hint
    hint: {
        fr: 'French language',
        en: 'English language',
        ar: 'Arabic language',
    },
    //footer
    footer: {
        copyright: '© Copyright',
        text: 'All rights reserved.',
    },
    ...Dashboard,
    ...Campaigns,
    ...Products,
    ...Databases,
    ...Backoffice,
    ...Buttons,
    ...Sidebar,
    ...Breadcrumbs,
    ...Messages,
    ...Fields,
    ...Info,
    ...Publicities,
}