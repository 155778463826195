<template>
  <v-dialog max-width="600"
            scrollable
            v-model="dialog">

    <v-card>
      <v-card-title>
        <v-spacer/>
        <v-icon @click="dialog=false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-4">
        {{ salepoint }}
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      salepoint: null,
    }
  },
  methods: {
    open(salepoint) {
      this.salepoint = salepoint
      this.dialog = true
    },
  }
}
</script>

<style scoped>

</style>