import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import app from './modules/application'
import campaign from './modules/campaign'
import publicity from './modules/publicity'
import api from './modules/api'

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    modules: {
        app,
        campaign,
        api,
        publicity,

    }

})
export default store
