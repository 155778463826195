import {taskObject} from "@/store/objects/taskObject";

export const investigationObject = {
    name: {
        fr: null,
        en: null,
        ar: null,
    },
    description: {
        fr: null,
        en: null,
        ar: null,
    },
    dates: [],
    image: null,
    amount: null,
    salepointIds: [],
    isModel: false,
    isActive: true,
    tasks: [taskObject]
};
