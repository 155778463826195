<template>
  <div id="app">
    <v-app
        :id="$lang === 'ar'?'noto-font':'nunito-font'"
        :class="!$vuetify.theme.dark ? 'bg-light' : ''">
      <vue-progress-bar></vue-progress-bar>
      <Navigation v-if="isLoggedIn"/>
      <v-main :class="isLoggedIn ? 'mx-4' : ''">
        <SuccessMessage/>
        <ErrorMessage/>
        <InfoMessage/>
        <router-view/>
        <v-btn v-scroll="onScroll"
               v-show="fab"
               fab dark fixed bottom
               right color="primary"
               @click="toTop">
          <v-icon color="white">mdi-chevron-up</v-icon>
        </v-btn>
      </v-main>
      <Footer v-if="isLoggedIn"/>
    </v-app>
  </div>
</template>
<script>
import Navigation from "@/navigation/Navigation";
import {HTTP} from "@/http-common";
import SuccessMessage from "@/components/SuccessMessage";
import ErrorMessage from "@/components/ErrorMessage";
import Footer from "@/navigation/Footer";
import InfoMessage from "@/components/InfoMessage";

export default {
  components: {InfoMessage, Footer, ErrorMessage, SuccessMessage, Navigation},
  data() {
    return {
      fab: false,
      isLoggedIn: this.$store.state.app.isLoggedIn,
      color: this.$vuetify.theme.currentTheme.primary
    }
  },
  methods: {
    getRaisons() {
      HTTP.get('/raisons').then(res => {
        this.$store.commit('api/raisons', res.data.data)
      })
    },
    getCities() {
      HTTP.get('/cities').then(res => {
        this.$store.commit('api/cities', res.data.data)
      })
    },
    getRegions() {
      HTTP.get('/regions').then(res => {
        this.$store.commit('api/regions', res.data.data)
      })
    },
    getQuestionTypes() {
      HTTP.get('/question-types').then(res => {
        this.$store.commit('api/questionTypes', res.data.data)
      })
    },
    getAnswerTypes() {
      HTTP.get('/answer-types').then(res => {
        this.$store.commit('api/answerTypes', res.data.data)
      })
    },
    getProducts() {
      HTTP.get('/products').then(res => {
        let data = res.data.data.filter(el => el.is_active === 1)
        this.$store.commit('api/products', data)
      })
    },
    getBrands() {
      HTTP.get('/brands').then(res => {
        this.$store.commit('api/brands', res.data.data)
      })
    },
    getCategories() {
      HTTP.get('/categories').then(res => {
        this.$store.commit('api/categories', res.data.data)
      })
    },
    getSubCategories() {
      HTTP.get('/subCategories').then(res => {
        this.$store.commit('api/subCategories', res.data.data)
      })
    },
    getSalepointAreas() {
      HTTP.get('/salepointAreas').then(res => {
        this.$store.commit('api/salepointAreas', res.data.data)
      })
    },
    getSalepointCircuits() {
      HTTP.get('/salepointCircuits').then(res => {
        this.$store.commit('api/salepointCircuits', res.data.data)
      })
    },
    getSalepointClusters() {
      HTTP.get('/salepointClusters').then(res => {
        this.$store.commit('api/salepointClusters', res.data.data)
      })
    },
    getSalepointRoutes() {
      HTTP.get('/salepointRoutes').then(res => {
        this.$store.commit('api/salepointRoutes', res.data.data)
      })
    },
    getSalepointSupplyTypes() {
      HTTP.get('/salepointSupplyTypes').then(res => {
        this.$store.commit('api/salepointSupplyTypes', res.data.data)
      })
    },
    getSalepointTypes() {
      HTTP.get('/salepointTypes').then(res => {
        this.$store.commit('api/salepointTypes', res.data.data)
      })
    },
    getSalepointZones() {
      HTTP.get('/salepointZones').then(res => {
        this.$store.commit('api/salepointZones', res.data.data)
      })
    },
    getDatabases() {
      HTTP.get('/databases').then(res => {
        this.$store.commit('api/databases', res.data.data)
      })
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    initPublicityTypes() {
      let types = [
        {
          "id": 1,
          "key": "0",
          "name": {
            "fr": "Petite",
            "en": "Small",
            "ar": "صغيرة"
          },
          "description": null,
          "icon": "mdi-move-resize"
        },
        {
          "id": 2,
          "key": "1",
          "name": {
            "fr": "Large",
            "en": "Large",
            "ar": "كبيرة"
          },
          "description": null,
          "icon": "mdi-monitor"
        }
      ];

      this.$store.commit('publicity/setPublicityTypes', types);

    }
  },
  created() {

    
    if (this.isLoggedIn) {
      this.getRaisons()
      this.getProducts()
      this.getBrands()
      this.getCategories()
      this.getSubCategories()
      this.getCities()
      this.getRegions()
      this.getQuestionTypes()
      this.getAnswerTypes()
      this.getSalepointAreas()
      this.getSalepointCircuits()
      this.getSalepointClusters()
      this.getSalepointRoutes()
      this.getSalepointSupplyTypes()
      this.getSalepointTypes()
      this.getSalepointZones()
      this.getDatabases()
      this.initPublicityTypes()
    }
  },
}
</script>

<style scoped>

</style>
