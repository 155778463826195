export default {
    btn: {
        nawCampain: 'New campaign',
        next: 'Next',
        back: 'Back',
        ok: 'OK',
        cancel: 'Cancel',
        save: 'Save',
        delete: 'Delete',
        update: 'Update',
        duplicate: 'Duplicate',
        add: 'Add',
        link: 'Show',
        filter: 'Filtering',
        refresh: 'Refresh',
        export: 'Exporter',
        drag: 'Drag',
        uploadImagePdf: 'Select a pdf or image file',
        show: 'Show',
        rename: 'Rename',
        download: 'Download',
        excel: 'Excel',
        search: 'Search',
        validate: 'Save the result',
        addConditions: 'Add conditions',
        settings: 'Settings'

    }
}
