<template>
  <div>

    <v-menu offset-y transition="slide-x-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="text-none" text>
          <v-avatar v-bind="attrs" v-on="on" size="20"
                    :class="$lang === 'ar' ? 'ml-1':'mr-1'">
            <v-img :src="require('@/assets/'+selectedLanguage+'.png')"></v-img>
          </v-avatar>
          {{ $t('lang.' + selectedLanguage.toLowerCase()) }}
        </v-btn>
      </template>

      <v-card>
        <v-card-text>
          <v-list nav dense>

            <v-list-item-group v-model="selectedLanguage" color="primary">

              <v-list-item value="FR">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/FR.png')"></v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ $t('lang.fr') }}</v-list-item-title>
              </v-list-item>

              <v-list-item value="EN">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/EN.png')"></v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ $t('lang.en') }}</v-list-item-title>
              </v-list-item>

              <v-list-item value="AR">
                <v-list-item-avatar size="25">
                  <v-img :src="require('@/assets/AR.png')"></v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ $t('lang.ar') }}</v-list-item-title>
              </v-list-item>

            </v-list-item-group>

          </v-list>
        </v-card-text>
      </v-card>

    </v-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: this.$store.state.app.lang
    }
  },
  watch: {
    selectedLanguage(payload) {
      this.$store.commit('app/lang', payload)
      this.$lang = payload
      this.$float = payload === 'AR' ? 'left' : 'right'
      this.$i18n.locale = payload
      this.$vuetify.rtl = payload === 'AR'
      this.$vuetify.lang.current = payload
    }
  }
}
</script>

<style scoped>

</style>