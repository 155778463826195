export default {
    database: {

        dialog: {

            addDatabase: 'Ajouter BDD',
            updateDatabase: 'Modifier BDD',
            renameDatabase: 'Renomer la BDD',


            addType: 'Ajouter un type',
            updateType: 'Modifier un type',

            addArea: 'Ajouter un superficie',
            updateArea: 'Modifier un superficie',

            addCluster: 'Ajouter un cluster',
            updateCluster: 'Modifier un cluster',

            addCircuit: 'Ajouter un circuit',
            updateCircuit: 'Modifier un circuit',

            addZone: 'Ajouter une zone',
            updateZone: 'Modifier une zone',

            addRoute: 'Ajouter une route',
            updateRoute: 'Modifier une route',

            addSupplyType: 'Ajouter un type d\'approvisionnement',
            updateSupplyType: 'Modifier un stype d\'approvisionnement',
        },

    }
}
