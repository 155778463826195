export default {
    message: {
        catchError: 'لقد حدث خطأ يرجى تصحيحه.',
        succssDelete: 'تم حذف هذا العنصر بنجاح.',
        succssStore: 'تمت إضافة هذا العنصر بنجاح.',
        succssUpdate: 'تم تحديث هذا العنصر بنجاح.',
        copyMessage: 'نسخ إلى الحافظة.',
        copy: 'نسخ',
        confirmation: 'تأكيد',
        confirmMessage: 'هل أنت متأكد أنك تريد حذف هذا العنصر؟',
        confirmSaveCampaign: 'هل أنت متأكد أنك تريد حفظ هذه الحملة؟',
        changeStatus: 'هل أنت متأكد أنك تريد تغيير حالة هذا العنصر؟',
        noDataTitle: 'لايوجد بيانات',
        noDataMessage: 'لم يتم العثور على سجلات مطابقة.',
        information: 'معلومة',
        itemsPerPageText: 'عدد الصفوف في الصفحة',
        all: 'الكل',
        noResultsText: 'لم يتم العثور على سجلات مطابقة',
        excelStore: 'إضافة عن طريق ملف اكسل',
        excelStoreMessage: 'الرجاء إدخال موضع لأي حقل في ملف اكسل الخاص بك.',
        assignment: 'التعيين',
        salepointRes: 'عدد نقاط البيع التي تم العثور عليها:',
        salepointAss: 'عدد نقاط البيع المشاركة :',
        searchRes: 'نتيجة البحث',
        salepoints: 'نقاط بيع',
        conditionMessage: 'أنقر فوق الزر أعلاه لإضافة شروط',
        waitingSave: 'جاري الحفظ ، الرجاء الانتظار ...',
        confirmSavePublicity: 'هل أنت متأكد أنك تريد حفظ هذا الإعلان؟'

    }
}
