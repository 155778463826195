<template>
  <div class="text-center pa-2">
    <v-avatar tile :size="size ? size : 250">
      <v-img :src="image ? image : imageSrc"></v-img>
    </v-avatar>
    <h3 class="text-uppercase">{{ $t('message.noDataTitle') }}</h3>
    <p class="my-2">
      {{ $t('message.noDataMessage') }}
    </p>
    <br>
  </div>
</template>

<script>
export default {
  props: ['size', 'image', 'text'],
  data() {
    return {
      imageSrc: require('@/assets/no-data.svg'),
    }
  }
}
</script>

<style scoped>

</style>