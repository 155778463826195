<template>
  <v-tooltip top color="info">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on"
            class="cursor-pointer"
            @click="copy">
        {{ text }}
      </span>
    </template>
    <span>{{ $t('message.copy') }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ['text'],
  methods: {
    async copy() {
      try {
        await navigator.clipboard.writeText(this.text);
        this.$infoMessage = this.$i18n.t('message.copyMessage')
      } catch ($e) {
        console.log($e)
      }
    }
  }
}
</script>

<style scoped>

</style>